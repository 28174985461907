import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ReactPlayer from 'react-player';

import { ContextConsumer } from '@context/context';
import Layout from '@organisms/Layout/Layout';
import Columns from '@atoms/Columns/Columns';
import Column from '@atoms/Column/Column';
import Hero from '@organisms/Hero/Hero';
import ContactForm from '@organisms/ContactForm/ContactForm';
import Container from '@atoms/Container/Container';
import Cards from '@atoms/Cards/Cards';
import Modal, { ModalLaunch } from '@molecules/Modal/Modal';

const PreservingCulturePageTemplate = ({
	hero,
	heroTablet,
	heroXLarge,
	title,
	video,
	videoDescription,
	videoDescriptionTrans,
	columns,
}) => {
	const images = {
		xlarge: heroXLarge.childImageSharp.fluid.src,
		desktop: hero.childImageSharp.fluid.src,
		tablet: heroTablet.childImageSharp.fluid.src,
	};
	return (
		<ContextConsumer>
			{({ videoSettings, setVideoSettings }) => {
				return (
					<>
						<Hero
							images={images}
							title={title}
							video={video}
							videoTitle=""
							videoDescription={videoDescription}
							videoDescriptionTrans={videoDescriptionTrans}
						/>
						<Container>
							<Columns>
								{columns.map(column => (
									<Column key={column.id}>
										<Cards>
											{styles => (
												<div className={styles.card} key={column.id}>
													<div className={styles.image}>
														<ModalLaunch className="launch-video">
															<img key={column.id} src={column.image} alt={column.alt} />
														</ModalLaunch>
														<Modal>
															<ReactPlayer
																key={column.id}
																url={column.video}
																width="100%"
																height="100%"
																onPlay={() =>
																	setVideoSettings(prevState => ({ ...prevState, playing: true, id: column.id }))
																}
																onPause={() => setVideoSettings(prevState => ({ ...prevState, playing: false, id: 0 }))}
																// eslint-disable-next-line eqeqeq
																playing={videoSettings.id == column.id}
															/>
														</Modal>
													</div>
													<p className={styles.title}>{column.title}</p>
													<p className={styles.text}>{column.text}</p>
													{column.textTrans && <p className={` ${styles.text} italic `}>{column.textTrans}</p>}
												</div>
											)}
										</Cards>
									</Column>
								))}
							</Columns>
						</Container>
						<ContactForm />
					</>
				);
			}}
		</ContextConsumer>
	);
};

PreservingCulturePageTemplate.propTypes = {};

const PreservingCulture = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	return (
		<Layout>
			<PreservingCulturePageTemplate
				hero={frontmatter.hero}
				heroTablet={frontmatter.heroTablet}
				heroXLarge={frontmatter.heroXLarge}
				title={frontmatter.title}
				video={frontmatter.video}
				videoTitle={frontmatter.videoTitle}
				videoDescription={frontmatter.videoDescription}
				videoDescriptionTrans={frontmatter.videoDescriptionTrans}
				columns={frontmatter.columns}
			/>
		</Layout>
	);
};

PreservingCulture.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}).isRequired,
};

export default PreservingCulture;

export const pageQuery = graphql`
	query PreservingCultureTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "preserving-our-culture" } }) {
			frontmatter {
				title
				video
				videoTitle
				videoDescription
				videoDescriptionTrans
				fullVideoUrl
				hero {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				heroTablet {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				heroXLarge {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				columns {
					id
					title
					text
					textTrans
					image
					video
					alt
				}
			}
		}
	}
`;
